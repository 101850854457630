import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    mode: "history",

    linkActiveClass: 'selected',
    linkExactActiveClass: 'selected',
    routes: [
        // Main application routes
        {   
            name: 'Homepage',
            path: '/', 
            component: () => import("../views/Index.vue"), 
            meta: {
                title: 'Inicio | F.I.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Pagina de inicio de F.I.N'
                    },
                    {
                        property: 'og:description',
                        content: 'Pagina de inicio de F.I.N'
                    }
                ]
            } 
        },

        { 
            name: 'Archive',
            path: '/archive', 
            component: () => import("../views/Archive.vue"), 
            meta: {
                title: 'Archivo Publico | F.I.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Encuentra todos los archivos publicados en F.I.N'
                    },
                    {
                        property: 'og:description',
                        content: 'Encuentra todos los archivos publicados en F.I.N'
                    }
                ]
            } 
        },

        { 
            name: 'Music',
            path: '/music', 
            component: () => import("../views/Music.vue"),
            meta: {
                title: 'Escucha en F.I.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Escucha música en F.I.N'
                    },
                    {
                        property: 'og:description',
                        content: 'Escucha música en F.I.N'
                    }
                ]
            }
        },

        { 
            name: 'Album Page',
            path: '/music/:id', 
            component: () => import("../views/Music.vue"),
            meta: {
                title: 'Escucha en F.I.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Escucha música en F.I.N'
                    },
                    {
                        property: 'og:description',
                        content: 'Escucha música en F.I.N'
                    }
                ]
            }
        },

        { 
            name: 'Blog',
            path: '/blog', 
            component: () => import('../views/Blog.vue'),
            meta: {
                title: 'Blog | F.I.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Lee los mas recientes comunicados de F.I.N'
                    },
                    {
                        property: 'og:description',
                        content: 'Lee los mas recientes comunicados de F.I.N'
                    }
                ]
            }
        },

        { 
            name: 'Reader',
            path: '/reader/sync/:id', 
            component: () => import('../views/Reader.vue'),
            meta: {
                title: 'Lector | F.I.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Disfruta de este articulo, patrocinado por F.I.N'
                    },
                    {
                        property: 'og:description',
                        content: 'Lee los mas recientes comunicados de F.I.N'
                    }
                ]
            }
            
        },

        { 
            name: 'Colection',
            path: '/col/:id', 
            component: () => import('../views/Collection.vue'),
            meta: {
                title: 'ColecciÓn | F.I.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Disfruta de esta colección patrocinada por F.I.N'
                    },
                    {
                        property: 'og:description',
                        content: 'Disfruta de esta colección patrocinada por F.I.N'
                    }
                ]
            }
        },

        // Admin panel routes
        { 
            path: '/admin', 
            component: () => import("../views/Admin.vue"),
            meta: {
                title: 'Admin Panel | A.T.O.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Panel de administración'
                    },
                    {
                        property: 'og:description',
                        content: 'Panel de administración'
                    }
                ]
            } 
        },
        { 
            path: '/admin/editor/:id', 
            component: () => import("../views/Editor.vue"),
            meta: {
                requiresAuth: true,
                title: 'Editor | A.T.O.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Editor de texto'
                    },
                    {
                        property: 'og:description',
                        content: 'Editor de texto'
                    }
                ]
            }
        },
        { 
            path: '/admin/dashboard', 
            component: () => import("../views/Dashboard.vue"),
            meta: {
                requiresAuth: true,
                title: 'Dashboard | A.T.O.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Pagina principal del panel de administración'
                    },
                    {
                        property: 'og:description',
                        content: 'Panel de administración'
                    }
                ]
            }
        },
        {
            path: '/admin/create-collection',
            component: () => import("../views/createCollection.vue"),
            meta: {
                requiresAuth: true
            },
            title: 'Create Collecton | A.T.O.N',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Crea una anomalia de coleccion'
                    },
                    {
                        property: 'og:description',
                        content: 'Crea una anomalia de coleccion'
                    }
                ]
        }
    ]
});

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const removeListener = onAuthStateChanged(
            getAuth(),
            (user) => {
                removeListener()
                resolve(user)
            },
            reject
        )
    })
}

router.beforeEach(async (to, from, next) => {
    const title = to.meta?.title ?? 'F.I.N'
    const titleFromParams = to.params.pageTitle

    if (title) {
        document.title = title
    }  
    
    if (titleFromParams) {
        document.title = `${titleFromParams | title}`
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (await getCurrentUser()) {
            next();
        } else {
            alert ('No investigue muchacho culiao !!!');
            next('/');
        }
    } else {
        next();
    }
})

export default router;