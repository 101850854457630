<template>
  <router-view />
</template>

<script>
</script>

<style>
body {
  background-color: black;
}

</style>
